import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';

import { StyledBody, SubmitContainer} from '../AddWellnessInitiative/styles';
import { UserButton } from '../EngagementSection/styles';
import { Bold, StyledModal } from './styles';
import { StyledTitle } from '../SocialFeeds/styles';


class ContactSuccessPopUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: ''
    }

    this.cancelButton =  React.createRef();
  }


  render() {
    const { showModal, title, subContent, onClose } = this.props;
    return(
      <StyledModal
        show={showModal}
        onHide={onClose}
        dialogClassName={'delete-popup'}
        backdropClassName='drop-back'
      >
        <Modal.Header closeButton>
          <StyledTitle>{title}</StyledTitle>
        </Modal.Header>
        <StyledBody>
          <Bold>
            <div className="subContent">
              {subContent}
            </div>
          </Bold>
          <SubmitContainer>
            <UserButton
              radius="5px"
              bgColor="#93bf3d"
              width="100px"
              height="36px"
              fontSize="12px"
              Align={1}
              float={1}
              marginLeft="10px"
              onClick={onClose}
              innerRef={this.cancelButton}
              outline={0}
            >
              Ok
            </UserButton>
          </SubmitContainer>
        </StyledBody>
      </StyledModal>
    );
  }
}

ContactSuccessPopUp.propTypes = {
  showModal: PropTypes.string,
  onClose: PropTypes.func,
  title: PropTypes.string.isRequired,
  subContent: PropTypes.string
};

export default ContactSuccessPopUp;
