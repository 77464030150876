import styled from 'styled-components';
import {Modal} from "react-bootstrap";
const Bold = styled.div`
  text-align: center;
  margin-bottom: 25px;
  margin-top: 25px;
  font-size: 18px;
  .subContent {
    text-align: center;
    color: rgb(153,153,153);
    font-size: 12px;
    margin-top: 5px;
  }
`;


const StyledModal = styled(Modal)`
  .modal-backdrop.in{
    opacity: .2;
  }
  .modal-dialog {
    transform: translate(-50%, -50%);
  }
  .delete-popup { 
    top: 30%;
    width: 351px;
    margin: 0 auto;
    .modal-header {
      border-bottom: 0px;
    } 
  }
 @media (max-width: 500px){
   .modal-dialog {
    width: 80%;
    }
 }
`;
export { Bold, StyledModal }