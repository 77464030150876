import styled from  'styled-components';
import { Modal } from 'react-bootstrap';

const BoldFont = 'Rubik-Bold';

const StyledBody = styled(Modal.Body)`
  padding: 0 !important;
`;

const StyledTitle = styled(Modal.Title)`
  font-family: Rubik-Medium !important;
  text-align: left !important;
  color: rgb(0, 92, 135) !important;
  width: 50% !important;
  font-size: 18px !important;
  >div{
    display: flex;
    justify-content: flex-end;
  >img{
    width: 20px;
    height: 20px;
  }
}
  font-family: Rubik-Medium !important;
`;

const DeviceContainer = styled.div`
  width: 100%;
  height: 75px;
  border-bottom: 1px solid #e2e2e4;
  padding: 0 6vw;
  display: table;
  
  > div {
    width: 50%;
    display: table-cell;
    vertical-align: middle;
  }

  @media (max-width: 500px) {
    padding: 5vw;
    > div {
      &:first-child {
        width: 70%;
      }
    }
  }

  @media (max-width: 321px) {
    padding: 3vw;
  }
`;

const RolesContainer = styled.div`
  width: 100%;
  height: 75px;
  border-top: ${({border}) => border ? '' : '1px solid #e2e2e4'};
  padding: 0 5vw;
  display: table;
  
  @media (max-width: 500px) {
    padding: 5vw;
    > div {
      &:first-child {
        width: 70%;
      }
    }
  }

  @media (max-width: 321px) {
    padding: 3vw;
  }
`;

const NameContainer = styled.div`
  width: ${({width}) => width ? width : '50%'};
  display: table-cell;
  vertical-align: middle;
`;

const LogoWrapper = styled.div`
  display: inline-block;
  padding-left: 10px;
    
  > div {
    font-size: 18px;
    text-transform: capitalize;

    font-family: Rubik-Medium;
   font-style: normal;
   font-weight: normal;
   font-size: 16px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #0D4270;
    font-family: Rubik-Medium;
  }
`;

const LinkedStatus = styled.span`
  font-size: 13px;
  font-family: Rubik-Medium;
  color: ${(props) => props.isLinked ? '#81d487' : '#f98387'};
`;

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  width: 42px;
  height: 42px;
  float: left;
  
  > img {
    width: 100%;
    float: left;
  }
`;

const CustomLabel = styled.label`
  position: relative;
  display: inline-block;
  width: 57px;
  height: 32px;
  margin-bottom: 0;
  left: calc(100% - 6vw);

  @media (max-width: 500px) {
    left: calc(100% - 60px);
  }
  
  > input {
    display: none;
  }
  
  > input:checked + .slider {
    background-color: #81d487;
  }

  > input:focus + .slider {
    box-shadow: 0 0 1px #81d487;
  }
  
  > input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
`;

const CustomCheckbox = styled.input`
  cursor: ${({ disabled }) => disabled ? 'not-allowed !important' : 'pointer'};
`;

const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #f98387;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 34px;
  
  &:before {
    position: absolute;
    content: "";
    height: 28px;
    width: 28px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 50%;
  }
  cursor: ${({ disabled }) => disabled ? 'not-allowed !important' : 'pointer'};
`;

const ButtonContainer = styled.div`
  margin: 20px 0 30px 0;
  text-align: center;
`;

const SaveButton = styled.button`
  background-color: #159fc9;
  border: 1px solid #159fc9;
  color: white;
  white-space: nowrap;
  padding: 10px 80px;
  font-size: 14px;
  line-height: 1.428571429;
  border-radius: 30px;
  text-transform: uppercase;
  font-family: ${BoldFont};
  
  &:focus, &:active, &:hover {
    outline: none;
  }
`;

const ClosePopUp = styled.div`
width: 50%;
display: flex;
justify-content: flex-end;
>img{
  width: 20px;
  height: 20px;
  position: relative;
  cursor: pointer;
}
`;

const CloseIcon = styled.div`
    display: flex;
    justify-content: flex-end;
    position: relative;
    bottom: 25px;
`;

const StyledModal = styled(Modal)`
  .modal-dialog { 
    .modal-header {
      border-bottom: 0px;
    }
    .modal-content{
      float: ${({width}) => width && 'left'};
      width:600px;
      margin-left:-50px;
    }
    @media (min-width: 700px){
      width:${({width}) => width};
    }
  }
  .reward-modal-header {
    text-align: right;
    color: #999;
    padding: 5px 15px;
    .close-icon {
      cursor: pointer;
    }
  }
`;

export { DeviceContainer, StyledBody, StyledTitle, LogoWrapper, ImageContainer, CustomLabel, CustomCheckbox, Slider, LinkedStatus, SaveButton, ButtonContainer, RolesContainer, NameContainer, CloseIcon, ClosePopUp, StyledModal };

