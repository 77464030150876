import styled, { css } from 'styled-components';
import { Modal } from 'react-bootstrap';

const NotificationWrapper =  styled.div`
position: absolute;
${'' /* top: -70px; */}
border-radius: 6px;
z-index: 1999;
box-shadow: 1px 1.732px 12px rgb(0 0 0 / 18%);
background-color: #fff;
width: 375px;
min-height: 100%;
right:0px;
  .read{
    background-color: #fff; 
  }

  @media (max-width: 350px) {
    width: 100%;
  }
`;
const TitleContainer = styled.div`
width: 100%;
min-height: 80px;
height: 15%;
background: linear-gradient(180deg, #52A8ED 0%, #8AD3F7 100%);
padding: 50px 15px 0px 15px;
color: #FFFFFF;
font-family: 'Rubik-Medium';
font-size: 18px;
line-height: 24px;
`;
const DeviceContainer = styled.div`
width: 100%;
height: 85%;
padding: 15px;
overflow: scroll;
::-webkit-scrollbar {
 width: 5px;
 height: 5px;
}
  > div: first-child {
    margin-bottom: 25px;
    > div: first-child {
      margin-bottom: 10px;
      color: #0D4270;
      font-family: 'Rubik-Medium';
      font-size: 18px;
      line-height: 19px;
    }
    >div: last-child {
      font-family: Rubik;
      font-size: 14px;
      color: #69C2FF;
    }
  }
  >div: last-child {
    display: flex;
		flex-flow: column;
    > div {
      display: flex;
      min-height: 90px;
      justify-content: space-between;
      padding: 15px 0px;
      border-bottom: 1px solid #ECECEC;
		}
		${({showScrollbar}) => showScrollbar && css`
			float: left;
			width: 100%;
			overflow-y: auto;
			height: 500px;
			::-webkit-scrollbar {
				width: 5px;
				height: 5px;
			}
			::-webkit-scrollbar-thumb {
				background: #c7c8c7;
				cursor: pointer;
				border-radius: 2.5px;
			}
			::-webkit-scrollbar-track {
				background: #fff;
				border-radius: 2.5px;
			}
		`}
  }
`;
const ImageContainer = styled.div`
width: 22%;
padding: 5px;
display: flex;
justify-content: center;
align-items: center;
${'' /* background: #F5F5F5; */}
border-radius: 3px;

${({width}) => width && css`
	> img {
		width: ${({width}) => width};
	}
`}
`;
const DeviceName = styled.div`
width: 50%;
display: flex;
justify-content: center;
align-items: flex-start;
flex-flow: column;
padding: 0px 15px;

>div: first-child {
  font-size: 18px;
  text-transform: uppercase;
  color: #000000;
  font-family: 'Rubik-Medium';
  line-height: 24px;
}
> div:last-child {
  font-family: 'Rubik-Regular';
  font-size: 14px;
  line-height: 24px;
  color: ${({linked}) => linked ? '#9FC989' : '#FD7175'};
}
`;
const ButtonContainer = styled.div`
width: 37%;
display: flex;
justify-content: center;
align-items: center;
`;
const Button = styled.button`
border-radius: 3px;
background-color: ${({linked}) => linked ? '#9FC989' : '#FD7175'};;
height: 30px;
width: 86px;
outline: none;
border: none;
color: #FFF;
font-family: 'Rubik-Medium';
font-size: 14px;
`;

const StyledModal = styled(Modal)`
  padding-right: 0px !important;
  .modal-dialog {
    width: auto;
    margin: 0px;
    max-width: none;
  }
`

const ScrollStyles = styled.div`
max-height: calc(100vh - 225px);
overflow: -moz-scrollbars-none;
::-webkit-scrollbar {
 width: 5px;
 height: 5px;
}

::-webkit-scrollbar-thumb {
 background: #c7c8c7;
 cursor: pointer;
 border-radius: 2.5px;
}

::-webkit-scrollbar-track {
 background: #fff;
 border-radius: 2.5px;
}
`;
export {
  NotificationWrapper, TitleContainer, DeviceContainer, ImageContainer, DeviceName, ButtonContainer, Button, StyledModal, ScrollStyles
}
